import React from "react";
import Home from "./components/Home";
import About from "./components/About";
import Roadmap from "./components/Roadmap";

import Partners from "./components/Partners";

function App() {
  return (
    <div>
      <Home />
      <About />
      <Roadmap />

      <Partners />
    </div>
  );
}

export default App;