import React, { Component } from "react";
import petelogo from "../img/talking-cats-talking_copy-removebg-preview.png";
import Telegram from "../img/Telegram_logo.svg.webp"
import Twitter from "../img/X_logo_2023.svg.png"


class Home extends Component {
  componentDidMount() {
    const burger = document.getElementById('burger');
    const navLinks = document.querySelector('.nav-links');

    burger.addEventListener('click', this.handleBurgerClick);
  }

  componentWillUnmount() {
    const burger = document.getElementById('burger');
    burger.removeEventListener('click', this.handleBurgerClick);
    console.log('Component is about to unmount!');
  }

  handleBurgerClick = () => {
    const navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('active');
  };

  scrollToAbout = () => {
    const aboutSection = document.getElementById('about-section');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };

  scrollToPartners = () => {
    const aboutSection = document.getElementById('partners');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };
  scrollToRoadmap = () => {
    const aboutSection = document.getElementById('roadmap-section');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    return (
      <div>
        <header id = "home-section">
          <div className="container">
            <nav className="navbar">
              <img src={petelogo} alt="micky logo" className="logo" />
              <ul className="nav-links">
                <li><a href="#">Home</a></li>
                <li onClick={this.scrollToAbout}><a href="#">About</a></li>
                <li onClick={this.scrollToRoadmap}><a href="#">Roadmap</a></li>
                <li onClick={this.scrollToPartners}><a href="#">Partner</a></li>
                <button>Buy $AC</button>
                <button>View Chart</button>
              </ul>
              <i className="fas fa-bars fa-2x" id="burger"></i>
            </nav>
            <section className="showcase">
              <h1>Aruging Cats</h1>
              <p>You know why arguing cats are arguing coz one cat didn't buy enough $AC</p>
              <div className="logos">
                <button className="custom-btn btn-6"> Buy $AC </button>
                <a href="https://t.me/arguing_cats" target="_blank" rel="noopener noreferrer" class="telegram-logo">
                <img src={Telegram} alt="telegram" className="telegram-logo" />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" class="twitter-logo">
                <img src={Twitter} alt="twitter" className="twitter-logo" />
                </a>
              </div>
      <p>CA: GuE8BfrjQjyvYKU98nu86dTaA222vRQr2hyxCGW6UYMP</p>
            </section>
          </div>

          <div className="video-container">
            
          </div>
        </header>
      </div>
    );
  }
}

export default Home;
