import React, { Component } from "react";
import Petemicky from "../img/talking-cats-talking_copy-removebg-preview.png"

class About extends Component {
    render() {
        return (
        <div>
            <div id = "about-section">
            <div className="about-header">
                        <h1></h1>
                    </div>
            <div className="about-container">
                <div className="about-img">
                        <img src={Petemicky} alt="Captain Pete" />
                     </div>
                    <div className="about-text">
                <h1></h1>
                <p>Look How scared poor kitty is coz he only bought 1 million</p>
                     </div>
                     
                </div>
            </div>
            </div>
        );
    }
}

export default About;