import React from "react";

class Roadmap extends React.Component {
  render() {
    return (
      <div>
        <roadmap id = "roadmap-section">
            <div className="container">
                <div className="roadmap-header">
                    <h1>Roadmap</h1>
                </div>
                <div class="cards">
                    <div class="card">
                        <h2>Stage 1</h2>
                        <p>Will try to stop aruging catsss</p>
                    </div>
                    <div class="card">
                        <h2>Stage 2</h2>
                        <p>Plan to hit 10000 holders in a month</p>
                    </div>
                    <div class="card">
                        <h2>Stage 3</h2>
                        <p>Became the first couple cat meme on crypto</p>
                    </div>
                    <div class="card">
                        <h2>Stage 4</h2>
                        <p>100 Million Market Capppp</p>
                    </div>
                    <div class="card">
                        <h2>Stage 5</h2>
                        <p>10 Billllionnn  Markett CAP</p>
                    </div>
                    </div>
            </div>
        </roadmap>
      </div>
    );
  }
}

export default Roadmap;