import React from 'react'
import dexview from '../img/dexview.svg'
import pinksale from '../img/pinksale.svg'

function Partners() {
  return (
    <div id='partners'>
      <h1 style={{fontFamily: 'Bungee'}}>Partners</h1>
      <div className='partners-container'>
        <div className='dexview'>
          <a href='https://dexview.com/' target='_blank' rel='noreferrer'>
            <img src={dexview} alt='dexview' />
          </a>
        </div>
        <div className='pinksale'>
          <a href='https://www.pinksale.finance/' target='_blank' rel='noreferrer'>
            <img src={pinksale} alt='pinksale' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Partners